
import {PropType, defineComponent} from 'vue';
import Collapse from '@/components/common/Collapse.vue';
import PitchMoment from '@/models/PitchMoment';
import PitchMomentForm from '@/components/pitchMoments/Form.vue';
import TimeAgo from '@/components/common/TimeAgo.vue';

export default defineComponent({
    components: {
        Collapse,
        PitchMomentForm,
        TimeAgo,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        pitchMoment: {
            type: Object as PropType<PitchMoment>,
            required: true,
        },
    },
});
