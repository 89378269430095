import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column justify-content-center align-items-center h-100"
}
const _hoisted_2 = { class: "mt-4 max-w-400px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Portal = _resolveComponent("Portal")!
  const _component_FormCollapse = _resolveComponent("FormCollapse")!
  const _component_PitchMomentsList = _resolveComponent("PitchMomentsList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('pitchMoments.index.title')), 1)
      ]),
      _: 1
    }),
    (_ctx.noPitchMoments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('pitchMoments.index.noPitchMomentsTitle')), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('pitchMoments.index.noPitchMomentsBody')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.teamProgram.id)
      ? _withDirectives((_openBlock(), _createBlock(_component_PitchMomentsList, {
          key: 1,
          options: _ctx.listOptions,
          "pitch-moments": _ctx.pitchMoments
        }, {
          default: _withCtx(({isUpcoming, pitchMoment}) => [
            _createVNode(_component_FormCollapse, {
              class: "bg-white rounded",
              open: isUpcoming,
              "pitch-moment": pitchMoment
            }, null, 8, ["open", "pitch-moment"])
          ]),
          _: 1
        }, 8, ["options", "pitch-moments"])), [
          [_vShow, !_ctx.noPitchMoments]
        ])
      : _createCommentVNode("", true)
  ]))
}